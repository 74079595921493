














































































































































import {Component, Prop} from "vue-property-decorator";
import Base from "@/data/model/Base";
import {Project, Status} from "@/data/model/Project";
import {DataTableHeader} from "vuetify";
import ProjectOverview from "@/components/ProjectOverview.vue";
import {OrderTypes} from "@/data/model/OrderElement";
import {Getter} from "vuex";
import Tooltip from "@/components/Tooltip.vue";

@Component({
  components: {Tooltip, CostumerOwerview3: ProjectOverview}
})
export default class ProjectTable extends Base {
  @Prop({required: true})
  projects!: Array<Project>

  @Prop({required: false, default: true})
  editable!: boolean

  expanded = []
  search = ''
  searchStatus = ""
  searchDate = ""
  selectedOrderTypes = []

  constructor() {
    super('project-table');
  }

  created() {
    let openOrderTypes = this.$store.getters.openOrderTypeForTable
    if (openOrderTypes) {
      this.selectedOrderTypes = openOrderTypes
    }
    this.$store.commit('removeOpenOrderTypeForTable')
  }

  get isAdmin(){
    return this.$store.state.applicationUser.role === 'ADMIN'
  }

  get noteSignalOptions(){
    return ['success' , 'warning' , 'error' , 'white']
  }

  get status() {
    let statusArray: any = []
    Object.keys(Status).slice(0, -1).forEach((key: string) =>
        statusArray.push({value: key, text: this.getLangTextWithoutPrefixAndWithoutHTML('status.' + key)})
    )
    return statusArray
  }

  get filteredProjects() {
    return this.projects
        .filter(p => this.searchStatus ? p.status!.toString() === this.searchStatus : true)
        .filter(p => this.searchDate ? p.startDate?.includes(this.searchDate) : true)
        .filter(p => this.selectedOrderTypes.length !== 0 ? this.filterProjectBySelectedOpenOrdertypes(p) : true)
  }

  filterProjectBySelectedOpenOrdertypes(project: Project) {

    const hasElementFromSearch = this.selectedOrderTypes.filter((selectedOrderTypes: string) => {
      const foundProjects = project.order?.filter(order => {
        if (order.name === selectedOrderTypes) {
          if (
              order.name === OrderTypes.FIREPROTECTION ||
              order.name === OrderTypes.SOUNDPROOFING ||
              order.name === OrderTypes.CONSTRUCTION_SUPERVISION ||
              order.name === OrderTypes.GEG_EVIDENCE ||
              order.name === OrderTypes.ENEV
          ) {

            if ((order.assigned && order.assigned.isAssigned) && !order.isOrder && (order.delivered && !order.delivered.isDelivered)) {
              return true
            } else {
              return false
            }
          }
          return !order.isOrder
        }
      });
      return foundProjects?.length !== 0
    })
    return hasElementFromSearch.length !== 0
  }

  get dates() {
    const dates = []
    const nowYear = new Date(Date.now()).getFullYear()
    dates.push(nowYear)
    for (let i = 0; i < 5; i++) {
      dates.push(nowYear + i)
      dates.push(nowYear - i)
    }
    return dates.sort()
  }

  get orderTypes(): { text: string, value: any } {
    const oStrings: any = []
    const o = Object.keys(OrderTypes).filter((item) => {
      return isNaN(Number(item));
    }).forEach(ordertype => {
      oStrings.push({
        text: this.getLangTextWithoutPrefix('orderTypes.' + ordertype),
        value: ordertype as String
      })
    });
    return oStrings
  }

  private updateProject(project: Project, color: "success" | "warning" | "error" | "white"){
    if(this.isAdmin){
      project.noteSignal = color
      this.$store.dispatch('updateProject', project)
    }
  }

  private colorByStatus({status}: Project) {
    switch (status) {
      case Status.RECEIVED:
        return 'warning'
        break;
      case Status.PREPARED_AND_READY:
        return 'warning'
        break;
      case Status.PREPARED_AND_INCOMPLETE:
        return 'error'
        break;
      case Status.IN_PROGRESS:
        return 'success'
        break;
      case Status.BLOCKED:
        return 'error'
        break;
      case Status.COMPLETED:
        return 'success'
        break;
    }
  }

  get headers(): Array<DataTableHeader> {
    let localheader: Array<DataTableHeader> = [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'noteSignal'
      },
      {
        text: this.getLangText('id'),
        align: 'start',
        sortable: false,
        value: 'id'
      },
      {
        text: this.getLangText('name'),
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: this.getLangText('type'),
        align: 'start',
        sortable: true,
        value: 'type'
      },
      {
        text: this.getLangTextWithoutPrefix('status.label'),
        align: 'start',
        sortable: true,
        value: 'status'
      },
      {
        text: this.getLangText('startDate'),
        align: 'start',
        sortable: true,
        value: 'startDate'
      },
      {
        text: this.getLangText('estimatedDeliveryDate'),
        align: 'start',
        sortable: true,
        value: 'estimatedDeliveryDate'
      },
      {
        text: this.getLangText('deliveryDate'),
        align: 'start',
        sortable: true,
        value: 'deliveryDate'
      }
    ]

    if(!this.isAdmin){
      localheader=localheader.slice(1, localheader.length)
    }

    if (this.editable) {
      localheader.push({
        text: 'Aktionen',
        align: 'start',
        sortable: false,
        value: 'actions',
      })
    }
    return localheader
  }
}








import {Component, Vue} from "vue-property-decorator";
import {Project, Status} from "@/data/model/Project";
import ProjectTable from "@/components/ProjectTable.vue";

@Component({
  components: {
    ProjectTable
  }
})

export default class ProjectOverview extends Vue {
  public projects: Array<Project> = new Array<Project>()

  created() {
    this.$store.dispatch('FETCH_OWN_PROJECTS', this.$store.state.applicationUser.customer.projectsNr)
        .then(p => this.projects = p)
  }

  private colorByStatus({status}: Project) {
    return Status.getValue(status)
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","label":_vm.getLangText('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"items":_vm.status,"label":_vm.getLangTextWithoutPrefix('status.label'),"clearable":""},model:{value:(_vm.searchStatus),callback:function ($$v) {_vm.searchStatus=$$v},expression:"searchStatus"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.dates,"label":_vm.getLangText('date'),"clearable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.orderTypes,"label":_vm.getLangText('orderType-open'),"multiple":"","clearable":""},model:{value:(_vm.selectedOrderTypes),callback:function ($$v) {_vm.selectedOrderTypes=$$v},expression:"selectedOrderTypes"}})],1):_vm._e()],1),_c('v-data-table',{attrs:{"item-key":"id","show-expand":"","dense":"","search":_vm.search,"items":_vm.filteredProjects,"headers":_vm.headers,"single-expand":true,"expanded":_vm.expanded,"no-data-text":_vm.getLangTextWithoutPrefix('table.no-data-text'),"no-results-text":_vm.getLangTextWithoutPrefix('table.no-results-text'),"footer-props":{
          'items-per-page-text': _vm.getLangTextWithoutPrefix('table.items-per-page-text'),
          'items-per-page-all-text': _vm.getLangTextWithoutPrefix('table.items-per-page-all-text')
        }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([(_vm.isAdmin)?{key:"item.noteSignal",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.noteSignal !== undefined && item.noteSignal !== 'white')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.noteSignal}},[_vm._v(" mdi-checkbox-blank-circle ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.noteSignalOptions),function(nso){return _c('v-list-item',{key:nso},[(nso !== undefined && nso !== 'white')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.updateProject(item,nso)}}},[_c('v-icon',{attrs:{"color":nso}},[_vm._v(" mdi-checkbox-blank-circle ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.updateProject(item,nso)}}},[_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)],1)}),1)],1)]}}:null,{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('costumer-owerview3',{attrs:{"project":item}})],1)]}},{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyDate")(item.startDate))+" ")]}},{key:"item.estimatedDeliveryDate",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getWeekNumber(item.estimatedDeliveryDate))+" "),(item.status === 'PREPARED_AND_INCOMPLETE' || item.status ===  'BLOCKED')?_c('tooltip',{attrs:{"text":_vm.getLangText('warning')}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1):_vm._e()],1)]}},{key:"item.deliveryDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("prettyDate")(item.deliveryDate))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{class:'pt-5 pb-5 ' + 'theme--light ' + _vm.colorByStatus(item),staticStyle:{"color":"#000"},attrs:{"light":""},domProps:{"innerHTML":_vm._s(_vm.getLangTextWithoutPrefix('status.'+ item.status))}})]}},(_vm.editable)?{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-delete")])],1)]}}:null,{key:"footer.page-text",fn:function(footer){return [_vm._v(" "+_vm._s(footer.pageStart + '-' + footer.pageStop + _vm.getLangTextWithoutPrefix('table.pagination-from-text') + footer.itemsLength)+" ")]}}],null,true)}),_vm._v(" "+_vm._s(_vm.getLangText('seeComments'))+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }